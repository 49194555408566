// src/pages/Home.scss

.home-container {
    padding: 60px 20px;
    text-align: center;
  
    .banner-container {
      position: relative;
      width: 100%;
      margin-bottom: 20px;
      overflow: hidden;
  
      .banner-image {
        width: 100%;
        height: auto;
        // border-radius: 2px;
        // filter: brightness(50%) blur(1px) // Làm mờ và tối banner để làm nổi bật chữ
      }

      .home-subtitle {
        text-transform: uppercase;
        font-size: 25px;
      }
  
      .banner-text {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: #ffffff;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        text-align: center;
  
        .home-title {
          font-weight: bold;
  
          .highlight {
            color: #00c6ff;
          }
        }
      }
    }
  
    .home-content {
      color: #555;
      margin: 20px auto;
      max-width: 700px;
      font-size: 1.25rem !important;
    }
  
    .home-info-circles {
      display: flex;
      justify-content: center;
      gap: 40px;
      margin: 40px 0;
  
      .home-circle {
        background: linear-gradient(145deg, #e0e0e0, #ffffff);
        border-radius: 50%;
        width: 200px;
        height: 200px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
  
        .home-circle-number {
          font-size: 2.5rem;
          font-weight: bold;
          color: #333;
        }
  
        .home-circle-text {
          font-size: 1rem;
          color: #666;
        }
      }
    }
  
    .home-additional-text {
      font-size: 1.25rem !important;
      max-width: 700px;
      margin: 0 auto;
      color: #555;
      line-height: 1.6;
  
      p {
        margin-bottom: 20px;
      }
    }
  
    .home-contact-button {
      border: 1px solid #007bff;
      color: #007bff;
      margin-top: 40px;
      background: #fff;
      border-radius: 6px;
      box-shadow: none;
  
      &:hover {
        border: 1px solid #fff;
        color: #fff;
        margin-top: 40px;
        background: #007bff;
        border-radius: 6px;
        box-shadow: none;
      }
    }
  
    /* Responsive Adjustments */
    @media (max-width: 768px) {
      .banner-container {
        .banner-image {
          height: 420px;
          object-fit: cover;
          filter: brightness(50%) blur(1px);
        }
  
        .banner-text {
          .home-title {
            font-size: 2rem;
          }
        }

        .home-subtitle {
          text-transform: uppercase;
          font-size: 22px;
          line-height: 35px;
          letter-spacing: 1.5px;
          margin: 15px 0;
        }
      }
    }
  }
  