// src/pages/Jobs.scss

.jobs-container {
    padding: 40px 20px;
    color: #333;
    text-align: center;
  
    .title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #333;
    }
  
    .intro {
      font-size: 1.2rem;
      margin-bottom: 40px;
      line-height: 1.6;
    }
  
    .no-jobs-box {
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      padding: 20px;
      border-radius: 8px;
      max-width: 600px;
      margin: 0 auto;
  
      .no-jobs-title {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #0072ff;
      }
  
      .no-jobs-content {
        font-size: 1rem;
        color: #666;
        line-height: 1.6;
      }
    }
  }
  