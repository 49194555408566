// src/pages/PrivacyPolicy.scss

.privacy-policy-container {
    padding: 40px 20px;
    line-height: 1.6;
    color: #333;
    text-align: left;
    
  
    .title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #333;
      text-align: left;
    }
  
    .section {
      margin-bottom: 30px;
  
      .section-title {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #333;
      }
  
      .section-content, .contact-info {
        font-size: 1rem;
        margin-bottom: 10px;
      }
  
      ul {
        margin: 10px 0;
        padding-left: 20px;
        list-style-type: disc;
  
        li {
          margin-bottom: 5px;
        }
      }
    }
  }
  