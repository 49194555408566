// src/components/Footer.scss

.footer {
    background-color: #01449B;
    // background: linear-gradient(to right, #143763, #143763, #143763, #1f60b6);
    color: #fff;
    padding: 35px 20px;
  
    .footer-container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 40px;
      max-width: 1200px;
      margin: 0 auto;
    }
  
    .footer-column {
      min-width: 150px;
      display: flex;
      flex-direction: column; // Sắp xếp các mục trong cột theo chiều dọc
  
      h6 {
        font-size: 1rem;
        margin-bottom: 10px;
        font-weight: bold;
      }
  
      .footer-link {
        font-size: 0.875rem;
        color: #ffffff;
        margin: 5px 0;
        text-decoration: none;
        display: block; // Mỗi mục chiếm toàn bộ chiều ngang của cột, giúp xuống dòng
        &:hover {
          color: #ffffff;
        }
      }
    }

    .footer-divider {
        width: 100%;
        height: 2px;
        margin: 20px 0; // Padding trên dưới cho divider
        background: linear-gradient(to right, #01449B, #00c6ff, #0072ff, #01449B); // Màu gradient
    }
  
    .footer-bottom {
      text-align: center;
      font-size: 0.875rem;
      color: #fff;
      margin-top: 10px;
    }
  
    @media (max-width: 768px) {
      .footer-container {
        flex-direction: column;
        align-items: left;
        display: flex;
        justify-content: space-between;
      }
    }
  }
  