// src/pages/TermsAndConditions.scss

.terms-container {
    padding: 40px 20px;
    color: #333;
    line-height: 1.6;
  
    .title {
      font-size: 2.5rem;
      margin-bottom: 30px;
      color: #333;
    }
  
    .section {
      margin-bottom: 30px;
  
      .section-title {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #333;
      }
  
      .section-content {
        font-size: 1rem;
        color: #555;
      }
    }
  }
  