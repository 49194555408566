.journey-container {
  padding: 40px 20px;
  text-align: left;
  
  .title {
    font-size: 2rem;
    font-weight: bold;
    color: #01449B;
    margin-bottom: 30px;
    line-height: 1.3;
    text-transform: uppercase;
  }

  .journey-section {
    margin-bottom: 30px;

    .subtitle {
      font-size: 1.4rem;
      font-weight: 500;
      color: #333;
      margin-bottom: 10px;
    }

    .content {
      font-size: 1rem;
      color: #555;
      line-height: 1.6;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media (max-width: 768px) {
    padding: 20px;

    .title {
      font-size: 1.8rem;
    }

    .subtitle {
      font-size: 1.2rem;
    }

    .content {
      font-size: 0.9rem;
    }
  }
}
