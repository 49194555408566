// src/pages/Products.scss

.products-container {
    padding: 40px 20px;
    color: #333;
    text-align: center;

    .title {
        font-size: 2.5rem;
        margin-bottom: 20px;
        color: #333;
    }

    .section {
        margin-bottom: 30px;
        text-align: left;

        .section-title {
            font-size: 1.5rem;
            margin-bottom: 10px;
            color: #01449B;
        }

        .section-content {
            font-size: 1rem;
            line-height: 1.6;
        }

        .feature-list {
            margin-top: 10px;
            padding-left: 20px;
            list-style-type: disc;

            li {
                margin-bottom: 5px;
            }
        }
    }

    .roadmap {
        display: flex;
        flex-direction: column;
        gap: 20px;

        .roadmap-item {
            background: #f9f9f9;
            border: 1px solid #ddd;
            padding: 20px;
            border-radius: 8px;

            .roadmap-title {
                font-size: 1.2rem;
                color: #333;
                margin-bottom: 5px;
            }

            .roadmap-content {
                font-size: 1rem;
                color: #666;
                line-height: 1.6;
            }
        }
    }
}
