// src/pages/Contact.scss

.contact-container {
    padding: 40px 20px;
    color: #333;
    text-align: center;
  
    .title {
      font-size: 2.5rem;
      margin-bottom: 20px;
      color: #333;
    }
  
    .intro {
      font-size: 1.2rem;
      margin-bottom: 30px;
      line-height: 1.6;
      max-width: 600px;
      margin: 0 auto 30px;
    }
  
    .contact-info {
      background-color: #f9f9f9;
      border: 1px solid #ddd;
      padding: 20px;
      border-radius: 8px;
      margin-bottom: 40px;
      text-align: left;
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
  
      h6 {
        font-size: 1.5rem;
        margin-bottom: 10px;
        color: #333;
      }
  
      p, .MuiTypography-body2 {
        font-size: 1rem;
        color: #555;
        line-height: 1.6;
        margin: 5px 0;
      }
    }
  
    .contact-form {
      max-width: 600px;
      margin: 0 auto;
      text-align: left;
  
      .MuiTextField-root {
        margin-bottom: 20px;
      }
  
      .submit-button {
        border: 1px solid #007bff;
        color: #007bff;
        background: #fff;
        border-radius: 6px;
        box-shadow: none;
  
        &:hover {
          border: 1px solid #fff;
          color: #fff;
          background: #007bff;
          border-radius: 6px;
          box-shadow: none;
        }
      }
    }
  }
  