// src/pages/SoftwareSolutions.scss

.software-solutions-container {
    padding: 60px 20px;

    .title {
        text-align: left;
        font-size: 3rem;
        font-weight: bold;
        color: #01449B;
        margin-bottom: 40px;
        text-transform: uppercase;
    }

    .text-column {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .subtitle {
            font-size: 1.5rem;
            font-weight: bold;
            color: #333;
            margin-bottom: 20px;
        }

        .description {
            font-size: 1rem;
            color: #555;
            line-height: 1.6;
            margin-bottom: 20px;
        }
    }

    .image-column {
        display: flex;
        justify-content: center;
        align-items: center;

        .image-container {
            max-width: 100%;
            height: auto;
            padding: 20px;

            .image {
                width: 100%;
                height: auto;
                border-radius: 8px;
                box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
            }
        }
    }

    @media (max-width: 768px) {
        .title {
            text-align: left;
        }

        .text-column,
        .image-column {
            text-align: left;
        }

        .image {
            max-width: 100%;
        }
    }
}
