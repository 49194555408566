/* src/components/Navbar.scss */

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  transition: background-color 2.8s linear, box-shadow 0.3s ease;

  &.scrolled {
    background-color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.22);
  }

  .footer-divider {
    width: 100%;
    height: 2px;
    background: linear-gradient(to right, #01449b, #00c6ff, #0072ff, #01449b);
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .logo-placeholder {
    font-weight: bold;
    font-size: 1.5rem;
    color: #333;
  }

  .nav-links {
    display: flex;
    align-items: center;
    gap: 20px;

    button {
      font-weight: normal;
      color: #333;
      text-transform: none;
      background: #fff;
      box-shadow: none;

      &:hover {
        color: #007bff;
      }

      &.active {
        font-weight: bold;
        color: #007bff;
        border-bottom: 2px solid #007bff;
      }
    }

    .MuiButton-root {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }

      span {
        display: flex;
        align-items: center;
      }
    }
  }

  .burger-menu {
    display: none;
  }

  @media (max-width: 960px) {
    .nav-links {
      display: none;
    }

    .burger-menu {
      display: inline-flex;
    }
  }
}

.drawer {
  .drawer-paper {
    width: 250px;
    text-transform: uppercase;
  }

  .drawer-header {
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: flex-end;
  }

  .MuiListItem-root {
    padding-left: 16px;

    &.active {
      background-color: rgba(0, 0, 0, 0.08);
      font-weight: bold;

      .MuiListItemText-root {
        color: #007bff;
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    .MuiListItemText-root {
      .MuiTypography-root {
        font-size: 1rem;
        font-weight: 500;
        color: #333;
      }
    }
  }
}
