.insights-container {
  padding: 60px 20px;
  text-align: left;
  line-height: 1.6;

  .insights-title {
    text-transform: uppercase;
    font-size: 2.5rem;
    font-weight: bold;
    color: #01449B;
    margin-bottom: 40px;
    text-align: left;
  }

  .insights-section {
    margin-bottom: 40px;

    .insights-subtitle {
      font-size: 1.8rem;
      font-weight: 500;
      color: #333;
      margin-bottom: 20px;
    }

    .insights-content {
      font-size: 1rem;
      color: #555;
      margin-bottom: 15px;
    }
  }

  .insights-conclusion {
    font-size: 1.1rem;
    font-weight: bold;
    color: #333;
    margin-top: 40px;
    text-align: left;
  }
}
