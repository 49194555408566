// src/pages/AboutUs.scss
.about-us-container {
    padding: 60px 20px;
    text-align: center;
    color: #333;
  
    .about-us-title {
      font-size: 2.5rem;
      font-weight: bold;
      margin-bottom: 20px;
      color: #01449B;
    }
  
    .about-us-content {
      font-size: 1.25rem;
      color: #555;
      margin: 20px auto;
      max-width: 700px;
      line-height: 1.6;
    }
  }
  