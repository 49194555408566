/* src/components/InfoCircles.scss */

.info-circles {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 40px 0;
  
    .circle {
      background: linear-gradient(145deg, #e0e0e0, #ffffff);
      border-radius: 50%;
      width: 200px;
      height: 200px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.1);
  
      .circle-number {
        font-size: 2.5rem;
        font-weight: bold;
        color: #333;
      }
  
      .circle-text {
        font-size: 1rem;
        color: #666;
        line-height: 1.6;
      }
    }
  
    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
    }
  }
  